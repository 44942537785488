import { Table, Typography } from "antd"

const { Summary } = Table
const { Row, Cell } = Summary
const { Text } = Typography

interface AdvStatisticsCampaignOfferTableSummaryProps {
  defaultColumns: string[]
  selectedColumns: string[]
  blindViews: string | number
  views: string | number
  clicks: string | number
}

export const AdvStatisticsCampaignOfferTableSummary = ({
  defaultColumns,
  selectedColumns,
  blindViews,
  views,
  clicks,
}: AdvStatisticsCampaignOfferTableSummaryProps): JSX.Element => {
  const getSummaryData = (column: string) => {
    switch (column) {
      case "image_id":
        return "SUM"
      case "blind_views":
        return blindViews
      case "views":
        return views
      case "clicks":
        return clicks
      default:
        break
    }
  }

  // filter defaultColumns for save their position
  const filteredColumns = defaultColumns.filter((col) =>
    selectedColumns.includes(col),
  )

  return (
    <Row>
      {filteredColumns.map((column, index) => (
        <Cell index={index} key={index}>
          <Text strong={true}>{getSummaryData(column)}</Text>
        </Cell>
      ))}
    </Row>
  )
}
