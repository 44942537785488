import { StdLabel } from "@/components/common/Std/Label"
import { DefaultOptionType } from "antd/es/select"
import { DatePicker, Flex, Select } from "antd"
import { TFunction } from "i18next"
import dayjs from "dayjs"

interface AdvStatisticsCampaignOfferFiltersProps {
  t: TFunction<"translation", undefined>
  onChangeStartDate: (_: any, dateString: string) => void
  onChangeEndDate: (_: any, dateString: string) => void
  onChangeCreativeId: (value: string) => void
  onChangeTextId: (value: string) => void
  creativesData: DefaultOptionType[] | undefined
  textsData: DefaultOptionType[] | undefined
  startDate: string
  endDate: string
}

export const AdvStatisticsCampaignOfferFilters = ({
  t,
  onChangeStartDate,
  onChangeEndDate,
  onChangeCreativeId,
  onChangeTextId,
  creativesData,
  textsData,
  startDate,
  endDate,
}: AdvStatisticsCampaignOfferFiltersProps): JSX.Element => {
  const ALL_OPTION: DefaultOptionType = {
    label: t("SELECTS.ALL"),
    value: "all",
  }

  return (
    <Flex gap="large" vertical style={{ maxWidth: "300px" }}>
      <StdLabel label={t("SELECTS.TEXT_PLACEHOLDER")}>
        <Select
          placeholder={t("SELECTS.TEXT_PLACEHOLDER")}
          onChange={onChangeTextId}
          defaultValue="all"
          showSearch
          options={textsData ? [ALL_OPTION, ...textsData] : [ALL_OPTION]}
        />
      </StdLabel>
      <StdLabel label={t("SELECTS.CREATIVE_PLACEHOLDER")}>
        <Select
          placeholder={t("SELECTS.CREATIVE_PLACEHOLDER")}
          onChange={onChangeCreativeId}
          defaultValue="all"
          showSearch
          options={
            creativesData ? [ALL_OPTION, ...creativesData] : [ALL_OPTION]
          }
        />
      </StdLabel>
      <Flex gap="large">
        <DatePicker
          onChange={onChangeStartDate}
          placeholder={t("SELECTS.START_DATE_PLACEHOLDER")}
          value={startDate ? dayjs(startDate) : null}
        />
        <DatePicker
          onChange={onChangeEndDate}
          placeholder={t("SELECTS.END_DATE_PLACEHOLDER")}
          value={endDate ? dayjs(endDate) : null}
        />
      </Flex>
    </Flex>
  )
}
