import { textToUpperCase } from "@/utils/textToUpperCase"
import { TFunction } from "i18next"

export const convertToCsv = (
  t: TFunction<"translation", undefined>,
  data: any,
  summaryData: any,
  selectedColumns: string[],
  defaultColumns: string[],
) => {
  if (!data || !data.length || !Array.isArray(data)) return ""

  const { clicks, blindViews, views, ctr, vctr, avarageCpc, cost } = summaryData

  const csvData = []
  const firstColumnTitle = "№"
  const summaryTitle = "SUM"

  // filter defaultColumns for save their position and get selectedColumn
  const filteredColumns = defaultColumns.filter((col) =>
    selectedColumns.includes(col),
  )

  // Add header row and translate keys
  const headerRow = filteredColumns.map((column) =>
    t(`ADV.STATISTICS.${textToUpperCase(column)}`),
  )
  csvData.push([firstColumnTitle, ...headerRow].join(","))

  // Filter data by selectedColumn and add data rows
  data.forEach((row: any, index: number) => {
    const newRow: any = {
      index: index + 1,
    }

    filteredColumns.forEach((col) => {
      newRow[col] = row[col]
    })

    const rowValues = Object.values(newRow)
    csvData.push(rowValues)
  })

  // Add summary data
  const summaryRow = [summaryTitle]

  const getSummaryData = (column: string) => {
    switch (column) {
      case "blind_views":
        return blindViews
      case "views":
        return views
      case "clicks":
        return clicks
      case "ctr":
        return ctr
      case "vctr":
        return vctr
      case "avarage_cpc":
        return avarageCpc
      case "cost":
        return cost
      default:
        return ""
    }
  }

  filteredColumns.forEach((col) => {
    summaryRow.push(getSummaryData(col))
  })

  csvData.push(summaryRow)

  return csvData.join("\n")
}
