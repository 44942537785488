import { Dispatch, SetStateAction } from "react"
import { TFunction } from "i18next"
import { StdLabel } from "@/components/common/Std/Label"
import { AdvertiserCampaignPad, AdvertiserCampaigns } from "@/definitions"
import { DatePicker, Flex, Select } from "antd"
import dayjs from "dayjs"

interface AdvStatisticsCampaignFiltersProps {
  t: TFunction<"translation", undefined>
  campaignsData: AdvertiserCampaigns[] | undefined
  campaign: string
  campaignsDataIsLoading: boolean
  padsData: AdvertiserCampaignPad[] | undefined
  padsDataIsLoading: boolean
  startDate: string
  endDate: string
  onChangeCampaign: (id: string) => void
  setPad: Dispatch<SetStateAction<string>>
  onChangeStartDate: (_: any, dateString: string) => void
  onChangeEndDate: (_: any, dateString: string) => void
  onChangeStartsBy: (value: string) => void
}

export const AdvStatisticsCampaignFilters = ({
  t,
  campaignsData,
  campaignsDataIsLoading,
  campaign,
  padsData,
  padsDataIsLoading,
  startDate,
  endDate,
  onChangeCampaign,
  setPad,
  onChangeStartDate,
  onChangeEndDate,
  onChangeStartsBy,
}: AdvStatisticsCampaignFiltersProps): JSX.Element => {
  const PAD_ALL = [
    {
      label: t("SELECTS.ALL"),
      value: "",
    },
  ]

  const padsOptions = padsData?.map((pad: AdvertiserCampaignPad) => ({
    label: pad.name,
    value: pad.id,
  }))

  return (
    <Flex vertical gap="large" style={{ width: "100%", maxWidth: "300px" }}>
      <StdLabel label={t("SELECTS.CAMPAIGN_PLACEHOLDER")}>
        <Select
          placeholder={t("SELECTS.CAMPAIGN_PLACEHOLDER")}
          optionFilterProp="children"
          loading={campaignsDataIsLoading}
          value={campaign}
          onChange={onChangeCampaign}
          options={campaignsData?.map((campaign: AdvertiserCampaigns) => ({
            label: `${campaign.campaign_id} ${campaign.name}`,
            value: campaign.campaign_id,
          }))}
        />
      </StdLabel>
      <StdLabel label={t("SELECTS.WEBSITE_PLACEHOLDER")}>
        <Select
          placeholder={t("SELECTS.WEBSITE_PLACEHOLDER")}
          optionFilterProp="children"
          loading={padsDataIsLoading}
          defaultValue={""}
          onChange={(value: string) => setPad(value)}
          options={padsOptions ? [...PAD_ALL, ...padsOptions] : PAD_ALL}
        />
      </StdLabel>
      <Flex gap="large">
        <DatePicker
          onChange={onChangeStartDate}
          placeholder={t("SELECTS.START_DATE_PLACEHOLDER")}
          value={startDate ? dayjs(startDate) : null}
        />
        <DatePicker
          onChange={onChangeEndDate}
          placeholder={t("SELECTS.END_DATE_PLACEHOLDER")}
          value={endDate ? dayjs(endDate) : null}
        />
      </Flex>
      <StdLabel label={t("SELECTS.STATISTICS_BY")}>
        <Select
          placeholder={t("SELECTS.STATISTICS_BY_PLACEHOLDER")}
          optionFilterProp="children"
          onChange={onChangeStartsBy}
          defaultValue={""}
          options={[
            { label: t("ADV.STATISTICS.NO_SORTING"), value: "" },
            { label: t("ADV.STATISTICS.PADS"), value: "pads" },
            { label: t("ADV.STATISTICS.DATES"), value: "dates" },
            { label: t("ADV.STATISTICS.OFFERS_AND_PADS"), value: "offers" },
          ]}
        />
      </StdLabel>
    </Flex>
  )
}
