import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { NavLink, useParams, useSearchParams } from "react-router-dom"
import { Breadcrumb, Flex, Switch, Table, Typography } from "antd"
import { ColumnsType } from "antd/es/table"
import { useGetAdvStatisticsCampaignOfferQuery } from "@/redux/slices/advertiserApi"
import { AdvStatisticsCampaignOfferFilters } from "@/components/advertiser/Statistics/Campaign/Offer/StatisticsCampaignOfferFilters"
import { AdvStatisticsCampaignOfferTableSummary } from "@/components/advertiser/Statistics/Campaign/Offer/StatisticsCampaignOfferTableSummary"
import { composeQueryParamsString } from "@/utils/composeQueryParamsString"
import { DEFAULT_PAGINATION_RANGE } from "@/constants"
import { AdvertiserStatisticsCampaignOfferItem } from "@/definitions"
import { StdSpinner } from "@/components/common/Std/Spinner"
import { StdErrorMessage } from "@/components/common/Std/ErrorMessage"
import { StdPaginationRange } from "@/components/common/Std/Selects/PaginationRange"
import { StdColumnsDropdown } from "@/components/common/Std/Dropdowns/ColumnsDropdown"
import dayjs from "dayjs"

const { Title } = Typography

const TABLE_COLUMNS_TITLES: string[] = [
  "image_id",
  "text_id",
  "blind_views",
  "views",
  "clicks",
  "ctr",
  "avarage_cpc",
  "payment_model",
  "price",
  "spend",
  "active",
]

export const AdvStatisticsCampaignOffer = (): JSX.Element => {
  const { t } = useTranslation()
  const { campaignId, offerId } = useParams()
  const [searchParams, setSearchParams] = useSearchParams()

  const [campaign, setCampaign] = useState<string>("")
  const [creativeId, setCreativeId] = useState<string>("")
  const [textId, setTextId] = useState<string>("")
  const [startDate, setStartDate] = useState<string>(
    dayjs().format("YYYY-MM-DD"),
  )
  const [endDate, setEndDate] = useState<string>(dayjs().format("YYYY-MM-DD"))
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [paginationRange, setPaginationRange] = useState<number>(
    DEFAULT_PAGINATION_RANGE,
  )
  const [selectedColumns, setSelectedColumns] =
    useState<string[]>(TABLE_COLUMNS_TITLES)

  const { data, isLoading, isError } = useGetAdvStatisticsCampaignOfferQuery(
    composeQueryParamsString({
      from: startDate,
      to: endDate,
      offer_id: offerId,
      text_id: textId,
      teaser_id: creativeId,
    }),
    { skip: !offerId },
  )

  const { data: selectsData } = useGetAdvStatisticsCampaignOfferQuery(
    composeQueryParamsString({
      from: startDate,
      to: endDate,
      offer_id: offerId,
    }),
    { skip: !offerId },
  )

  useEffect(() => {
    setCampaign(campaignId || "")
  }, [campaignId])

  useEffect(() => {
    const queryStartDate = searchParams.get("start")
    const queryEndDate = searchParams.get("end")

    if (queryStartDate) {
      setStartDate(queryStartDate)
    }

    if (queryEndDate) {
      setEndDate(queryEndDate)
    }
  }, [])

  const TABLE_COLUMNS: ColumnsType<AdvertiserStatisticsCampaignOfferItem> = [
    {
      title: t("ADV.STATISTICS.IMAGE_ID"),
      dataIndex: "image_id",
      key: "image_id",
      sorter: (a, b) => Number(a.image_id) - Number(b.image_id),
    },
    {
      title: t("ADV.STATISTICS.TEXT_ID"),
      dataIndex: "text_id",
      key: "text_id",
      sorter: (a, b) => Number(a.text_id) - Number(b.text_id),
    },
    {
      title: t("ADV.STATISTICS.BLIND_VIEWS"),
      dataIndex: "blind_views",
      key: "blind_views",
      sorter: (a, b) => Number(a.blind_views) - Number(b.blind_views),
    },
    {
      title: t("ADV.STATISTICS.VIEWS"),
      dataIndex: "views",
      key: "views",
      sorter: (a, b) => Number(a.views) - Number(b.views),
    },
    {
      title: t("ADV.STATISTICS.CLICKS"),
      dataIndex: "clicks",
      key: "clicks",
      sorter: (a, b) => Number(a.clicks) - Number(b.clicks),
    },
    {
      title: t("ADV.STATISTICS.CTR"),
      dataIndex: "ctr",
      key: "ctr",
      sorter: (a, b) => Number(a.ctr) - Number(b.ctr),
    },
    {
      title: t("ADV.STATISTICS.AVARAGE_CPC"),
      dataIndex: "avarage_cpc",
      key: "avarage_cpc",
      sorter: (a, b) => Number(a.avarage_cpc) - Number(b.avarage_cpc),
    },
    {
      title: t("ADV.STATISTICS.PAYMENT_MODEL"),
      dataIndex: "payment_model",
      key: "payment_model",
      sorter: (a, b) => a.payment_model.localeCompare(b.payment_model),
    },
    {
      title: t("ADV.STATISTICS.PRICE"),
      dataIndex: "price",
      key: "price",
      sorter: (a, b) => Number(a.price) - Number(b.price),
    },
    {
      title: t("ADV.STATISTICS.SPEND"),
      dataIndex: "cost",
      key: "spend",
      sorter: (a, b) => Number(a.cost) - Number(b.cost),
    },
    {
      title: t("ADV.STATISTICS.ACTIVE"),
      dataIndex: "active",
      key: "active",
      sorter: (a, b) => a.active.localeCompare(b.active),
      render: (value: string) => (
        <Switch
          checkedChildren={t("BUTTONS.ON")}
          unCheckedChildren={t("BUTTONS.OFF")}
          defaultChecked={value === "on"}
          disabled
        />
      ),
    },
  ]

  const summaryData = {
    blindViews: data?.sum?.views_all_sum || "0",
    views: data?.sum?.views_sum || "0",
    clicks: data?.sum?.clicks_sum || "0",
  }

  const creativesData = selectsData?.teasers?.map((creative) => ({
    label: (
      <Flex gap={15} align="center">
        {creative?.teaser_id}
        <img
          src={creative?.teaser_url}
          alt=""
          style={{ width: "auto", height: "28px", objectFit: "contain" }}
        />
      </Flex>
    ),
    value: creative?.teaser_id,
  }))

  const textsData = selectsData?.texts?.map((text) => ({
    label: `${text?.text_id} ${text?.text}`,
    value: `${text?.text_id} - ${text?.text}`,
  }))

  const handlePageChange = (page: number): void => {
    setCurrentPage(page)
  }

  const onChangeStartDate = (_: any, dateString: string): void => {
    setStartDate(dateString)

    setSearchParams(
      (prev) => {
        const params = new URLSearchParams(prev.toString())

        params.set("start", dateString)

        return params.toString()
      },
      { replace: true },
    )
  }

  const onChangeEndDate = (_: any, dateString: string): void => {
    setEndDate(dateString)

    setSearchParams(
      (prev) => {
        const params = new URLSearchParams(prev.toString())

        params.set("end", dateString)

        return params.toString()
      },
      { replace: true },
    )
  }

  const onChangeTextId = (value: string): void => {
    if (value === "all") {
      setTextId("")
      return
    }

    const textIdFromValue = value.split(" - ")[0]

    setTextId(textIdFromValue)
  }

  const onChangeCreativeId = (value: string): void => {
    if (value === "all") {
      setCreativeId("")
      return
    }

    setCreativeId(value)
  }

  if (isLoading) return <StdSpinner />

  if (isError) return <StdErrorMessage>Error...</StdErrorMessage>

  return (
    <Flex vertical gap="large">
      <Flex gap="large" justify="space-between" align="end" wrap="wrap">
        <Flex vertical>
          <Breadcrumb
            items={[
              {
                title: (
                  <NavLink to={"/advertiser/statistics"}>
                    {t("ADV.STATISTICS.CAMPAIGNS")}
                  </NavLink>
                ),
              },
              {
                title: (
                  <NavLink to={`/advertiser/statistics/${campaign}`}>
                    {`${t("ADV.STATISTICS.CAMPAIGN_ID")}: ${campaign}`}
                  </NavLink>
                ),
              },
              {
                title: `${t("ADV.STATISTICS.OFFER_ID")}: ${offerId}`,
              },
            ]}
          />
          <Title level={4} style={{ paddingBottom: "5px" }}>
            {t("ADV.STATISTICS.OFFER_STATISTICS")}
          </Title>
          <AdvStatisticsCampaignOfferFilters
            t={t}
            startDate={startDate}
            endDate={endDate}
            onChangeStartDate={onChangeStartDate}
            onChangeEndDate={onChangeEndDate}
            onChangeTextId={onChangeTextId}
            onChangeCreativeId={onChangeCreativeId}
            creativesData={creativesData}
            textsData={textsData}
          />
        </Flex>
        <Flex gap="large">
          <StdPaginationRange
            t={t}
            paginationRange={paginationRange}
            setPaginationRange={setPaginationRange}
          />
          <StdColumnsDropdown
            t={t}
            namespace="ADV.STATISTICS"
            options={TABLE_COLUMNS_TITLES}
            selectedOptions={selectedColumns}
            setSelectedOptions={setSelectedColumns}
          />
        </Flex>
      </Flex>

      <div style={{ position: "relative" }}>
        <Table
          columns={TABLE_COLUMNS.filter((col: any) =>
            selectedColumns.includes(col?.key),
          )}
          dataSource={Array.isArray(data?.data) ? data?.data : []}
          rowKey="id"
          scroll={{ x: "max-content" }}
          showSorterTooltip={false}
          sortDirections={["ascend", "descend", "ascend"]}
          summary={() => {
            if (!!data?.data?.length) {
              return (
                <AdvStatisticsCampaignOfferTableSummary
                  selectedColumns={selectedColumns}
                  defaultColumns={TABLE_COLUMNS_TITLES}
                  views={summaryData.views}
                  clicks={summaryData.clicks}
                  blindViews={summaryData.blindViews}
                />
              )
            }
          }}
          pagination={{
            position: ["bottomCenter"],
            current: currentPage,
            pageSize: paginationRange,
            showSizeChanger: false,
            onChange: handlePageChange,
            total: data?.data?.length,
            showTotal: (total, range) => {
              if (range[1] > 0) {
                return (
                  <span className="paginationShowTotal">
                    {t("PAGINATION.SHOW_TOTAL", {
                      firstNumber: range[0],
                      secondNumber: range[1],
                      total: total,
                    })}
                  </span>
                )
              }
            },
          }}
        />
      </div>
    </Flex>
  )
}
